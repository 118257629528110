
section.flyout {
    position: fixed;
    bottom: -1px;
    max-width: 390px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999991;
}

section.flyout strong {
    font-family: 'Avenir-Bold' !important;
}

section.flyout > header {
    background-color: #171717;
    padding: 1.5rem 0 .65rem 0;
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
    justify-content: center;
    cursor: pointer;
}

section.flyout > header button {
    border: none;
    color: white;
    background: none;
    transform: rotate(180deg);
    outline: none;
    position: absolute;
    bottom: calc(100% - 22px);
}

section.flyout.flyout--hidden > header button {
    transform: rotate(0);
}

section.flyout.flyout--hidden .flyout__content{
    display: none;
}

section.flyout > header span {
    letter-spacing: 1.45px;
    color: white;
    font-size: 20px;
}

section.flyout .flyout__content {
    background: #FAFAFA;
    display: inline-block;
    width: 100%;
}

section.flyout .flyout__buttons div {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}

section.flyout .flyout__buttons button {
    text-transform: none;
    height: 45px;
    min-width: 305px;
}

section.flyout .flyout__buttons .btn-red {
    background-color: #EA5344;
    border: 1px solid #EA5344;
}

section.flyout .flyout__buttons .btn-dark {
    background-color: #292929;
    border: 1px solid #292929;
}

section.flyout .flyout__products{

    border-top: 1px solid #CDCDCD;
}
section.flyout .flyout__thumbnails {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
}

section.flyout .flyout__thumbnails .thumbnail--product {
    border-radius: 2px;
    overflow: hidden;
    border: 1px solid #e3e3e3;
}

section.flyout .flyout__thumbnails .thumbnail .delete--product svg path{
    width: 15px;
    height: 15px;
}

section.flyout .flyout__thumbnails .thumbnail .delete--product {
    position: absolute;
    bottom: 80%;
    right: .12rem;
}

section.flyout .flyout__products .thumbnail {
    position: relative;
    max-width: 90px;
    max-height: 130px;
    overflow: hidden;
}

section.flyout .flyout__products .flyout__product {
    display: inline-block;
    margin: auto;
    width: 100%;
}

section.flyout .flyout__products .flyout__plusIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/** alerts */

section.flyout .flyout__alert {
    background-color: #3E403D;
    width: 95%;
    color: white;
    margin: 1rem auto;
    font-size: 16px;
    padding: .85rem 1rem;
    border-radius: 4.5px;
}

section.flyout .flyout__alert--fullWidth {
    border-radius: 0px;
    width: 100%;
    padding: .85rem 1.5rem;
}

section.flyout .flyout__alert--success {
    background-color: #2FAA71;
}

/* Responsive Flyout */
@media screen and (min-width: 991px) {
    
    section.flyout {
        left: 100%;
        transform: translateX(-100%);
        top: 90px;
        bottom: auto;
        width: 240px;
        /*position: absolute;*/
    }

    @media screen and (min-height: 790px) {
        section.flyout {
            top: 140px;
        }
    }

    section.flyout > header {
        position: absolute;
        right: calc(100% - 1px);
        top: 5rem;
        border-radius: 13px 13px 0 0;
        width: 260px;
        transform: rotate(-90deg) translateY(-100%);
        transform-origin: 100% 0;
        padding: 1rem 0.75rem .1rem 0.75rem;
    }
    section.flyout > header button {
        bottom: calc(100% - 18px);
    }
    section.flyout > header button img {
        width: 16px;
        height: 16px;
    }
    section.flyout > header span {
        font-size: 15.5px;
    }

    section.flyout.flyout--hidden > header {
        right: 0;
    }

    section.flyout .flyout__content {
        border: 1px solid #E5E5E5;
        background-color: #F5F5F5;
        border-radius: 12px 0 0 12px;
    }

    section.flyout[data-count="3"]:not(.flyout--hidden) .flyout__content {
        display: flex;
        flex-direction: column;
        padding-top: 1.55rem;
    }

    section.flyout .flyout__buttons{
        background: none;
        margin-top: .45rem;
    }

    section.flyout[data-count="3"] .flyout__buttons {
        order: 2;
        padding-bottom: 1.55rem;
    }

    section.flyout .flyout__buttons p {
        font-size: 24px;
        text-align: center;
    }

    section.flyout .flyout__buttons p.or {
        font-size: 1em;
        text-align: center;
    }
    section.flyout .flyout__buttons button {
        font-size: 10px;
        height: 28px;
        margin-top: 3px;
        min-width: 170px;
    }

    section.flyout .flyout__buttons div {
        background-color: transparent;
    }

    section.flyout .flyout__products {
        border: 0;
    }

    section.flyout[data-count] .flyout__products {
        display: flex;
        flex-direction: column;
    }

    section.flyout[data-count="3"] .flyout__products {
       order: 1;
    }

    section.flyout[data-count] .flyout__products .flyout__thumbnails {
        order: 1;
    }

    section.flyout .flyout__thumbnails {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        gap: .35rem;
        flex-direction: column;
    }

    section.flyout[data-count="3"] .flyout__thumbnails {
        flex-direction: row;
        margin: 0 1rem;
    }

    section.flyout .flyout__thumbnails .thumbnail {
        width: 75px;
        height: 108px;
    }

    section.flyout[data-count="3"] .flyout__thumbnails .thumbnail {
        width: 68;
        height: 94px;
    }
    
    section.flyout .flyout__alert {
        font-size: 12px;
        line-height: 16px;
    }

    section.flyout[data-count] .flyout__products .flyout__alert {
        order: 2;
    }
}